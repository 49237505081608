<template>
  <div>
    <Card title="Dose Recommendation" :loading="saving || loading">
      <DosageInfo
        v-if="!loading && schema"
        :general-details="patientDetails.generalDetails"
        :dose-details="patientDetails.dosedetails"
        :suggested-dose-details="patientDetails.suggested_dosedetails"
      />
      <ModifyDosage
        ref="modifyDosage"
        v-if="!loading && schema.length > 0"
        :general-details="patientDetails.generalDetails"
        :dose-details="patientDetails.dosedetails"
        :suggested-dose-details="patientDetails.suggested_dosedetails"
        :enable-navigation="enableNavigation"
        :saving="saving"
        :allowUserToEditOrNot="allowUserToEditOrNot"
        v-on="$listeners"
        :schema="_.find(schema, { type: 'modifyDose' }).fields"
        @commonFunction="commonFunction"
        @change="onChange($event, field)"
      />
      <TargetAttainment
        v-if="!loading && schema.length > 0"
        :suggested-metrics="patientDetails.suggestedSuccessMetrics"
        :success-metrics="patientDetails.successMetrics"
        :schema="_.find(schema, { type: 'metrics' }).fields[0].fields"
      />
      <hr />
      <Metrics
        v-if="!loading && schema.length > 0"
        :success-metrics="patientDetails.successMetrics"
        :pk-parameters="patientDetails.pkparameters"
        :schema="_.find(schema, { type: 'metrics' }).fields[1].fields"
      />
      <PKParameters
        v-if="!loading && schema.length > 0"
        :success-metrics="patientDetails.successMetrics"
        :pk-parameters="patientDetails.pkparameters"
        :schema="_.find(schema, { type: 'parameters' }).fields"
      />
    </Card>
  </div>
</template>

<script>
import Card from "@/new-components/ui/Card";
import DosageInfo from "@/new-components/patient/DosageInfo";
import ModifyDosage from "@/new-components/patient/ModifyDosage";
import TargetAttainment from "@/new-components/patient/TargetAttainment";
import PKParameters from "@/new-components/patient/PKParameters";
import Metrics from "@/new-components/patient/Metrics";
import _ from "lodash";
// import { Http } from "@/services/service";
// import { orderBy } from "lodash";

export default {
  name: "DoseRecommendationTab",

  components: {
    PKParameters,
    TargetAttainment,
    DosageInfo,
    ModifyDosage,
    Card,
    Metrics,
  },

  props: {
    loading: { type: Boolean, required: true },
    saving: { type: Boolean, required: true },
    enableNavigation: { type: Boolean, required: true },
    patientDetails: { type: Object, required: true },
    allowUserToEditOrNot: { type: Boolean, required: false },
    schema: { type: Array, required: true}
  },
  computed: {
    _() {
      return _;
    },
  },
  methods: {
    commonFunction(v, key) {
      this.$emit("commonFunction", v, key);
    },
    triggerValidate() {
    this.$refs.modifyDosage.validate = false;
    this.$nextTick(() => {
      this.$refs.modifyDosage.validate = true;
    });
  },
  },
};
</script>

<style scoped>
hr {
  opacity: 0.1;
}
</style>