<template>
  <v-flex
  >
    <PercentageGraph visible :data="graphData" :title="graphTitle" />
  </v-flex>
</template>
<script>
import PercentageGraph from "@/components/PercentageGraph";

export default {
  name: "Attainment",

  components: { PercentageGraph },

  props: {
    suggestedMetric: { type: Number, required: true },
    successMetric: { type: Number, required: true },
     graphTitle: { type: String, required: true },
  },

  computed: {
    graphData() {
      var xValue = [this.suggestedMetric, this.successMetric];
      var yValue = ["recommended", "current"];
      return [
        {
          x: xValue,
          y: yValue,
          type: "bar",
          text: xValue.map(String),
          textposition: "auto",
          hoverinfo: "x",
          orientation: "h",
          marker: {
            color: ["#CA8861", "#675193"],
          },
        },
      ];
    },
  },
};
</script>
