<template>
  <v-layout>
     <div v-for="(sub_input, j) in field.sub_fields" :key="j">
        <v-flex xs12 :class="j > 0 ? 'pl-5' : ''" >
           <NumberInput :label="j == 0 ? field.label: ''"  v-if="patientDetails[field.key]" :value="patientDetails[field.key][sub_input.key]" :append-text="sub_input.label" disabled /> 
           <NumberInput v-else :label="j == 0 ? field.label: ''" value="" :append-text="sub_input.label" disabled /> 
        </v-flex>
    </div>
  </v-layout>
</template>

<script>
import NumberInput from "@/new-components/ui/NumberInput";
// import Dropdown from "@/new-components/ui/Dropdown";
// import Date from "@/new-components/ui/Datepicker";
// import PatientAge from "@/new-components/patient/PatientAge";
// import patientAgeMixin from "@/mixins/patientAgeMixin";
// import Radio from "@/new-components/ui/Radio";
export default {
  name: "SubFields",
 components: {
    NumberInput
  },
  props: {
    patientDetails:{},
    subFields: Array,
    field:Object,
  },
  created() {
  },
};
</script>