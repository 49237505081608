<template>
  <v-layout row>
    <v-flex xs12 pb-1>
      <div ref="plotContainer" class="plot-container"></div>
    </v-flex>
  </v-layout>
</template>

<script>
import Plotly from "plotly.js-dist";
import { toLocalDateTimeString } from "@/misc/utils";

export default {
  name: "TimelineGraph",
  props: {
    visible: Boolean,
    graphData: Array,
  },
  data() {
    return {
      currentTimeTrace: {},
    };
  },
  methods: {
    onResize() {
      this.layout.width = this.$refs.plotContainer.offsetWidth;
      this.showPlot();
    },
    showPlot() {
      Plotly.react(
        this.$refs.plotContainer,
        this.fixedData,
        this.layout,
        this.config
      );
    },
  },
  mounted() {
    console.log(this.graphData)
    this.showPlot();
  },
  watch: {
    data() {
      this.showPlot();
    },
    visible() {
      this.onResize();
    },
  },
  computed: {
    fixedData() {
      let out = [];
      for (let trace of this.graphData.data) {
        if (trace.x) {
          for (const ind in trace.x) {
            trace.x[ind] = toLocalDateTimeString(trace.x[ind]);
          }
        }
        out.push(trace);
      }
      out.push(this.currentTimeTrace);
      return out;
    },
    layout(){
      return this.graphData.layout
    },
    config(){
      return this.graphData.config
    }

  },
};
</script>

<style lang="scss" scoped>
.plot-container {
  height: 550px;
}
</style>
