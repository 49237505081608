<template>
  <v-flex>
    <div ref="plotContainer" class="plot-container" v-resize="onResize"></div>
  </v-flex>
</template>
<script>
import Plotly from "plotly.js-dist";
export default {
  name: "PercentageGraph",
  props: {
    visible: Boolean,
    data: Array,
    title: String,
  },
  data() {
    return {
      config: {
        responsive: true,
        displayModeBar: false,
        staticPlot: false,
      },
      layout: {
        title:{
           text: this.title,
           xref: 'paper',
           x: 100,
        },
        xaxis: {
          fixedrange: true,
          title: {
            text: "%",
            x: 1,
          },
          range: [0, 100],
          showline: true,
          mirror: true,
        },
        yaxis: {
          fixedrange: true,
          showline: true,
          mirror: true,
        },
         margin: {
          l: 106,
          t: 50,
          b: 50,
          r: 12,
        },
        height: 160,
        font: {
          font: "Roboto,sans-serif",
          size: 13
        }
      },
    };
  },
  methods: {
    onResize() {
      this.layout.width = this.$refs.plotContainer.offsetWidth;
      Plotly.react(
        this.$refs.plotContainer,
        this.data,
        this.layout,
        this.config
      );
      // if (window.innerWidth > 500) {
      //   this.layout.title.x = 0;
      //   Plotly.react(
      //   this.$refs.plotContainer,
      //   this.data,
      //   this.layout,
      //   this.config
      // );
      // } else {
      //  this.layout.title.x = 100;
      //   Plotly.react(
      //   this.$refs.plotContainer,
      //   this.data,
      //   this.layout,
      //   this.config);
      // }
    },
  },
  mounted() {
    Plotly.react(this.$refs.plotContainer, this.data, this.layout, this.config);
  },
  watch: {
    data() {
      Plotly.react(
        this.$refs.plotContainer,
        this.data,
        this.layout,
        this.config
      );
    },
    visible() {
      // eslint-disable-line no-unused-vars
      this.onResize();
    },
    // created() {
    //   window.addEventListener('resize', this.onResize)
    // },

    // beforeDestroy() {
    //   window.removeEventListener('resize', this.onResize)
    // },
  },
};
</script>

<style lang="scss">
.gtitle{
 font-size:14px !important;
}
@media screen and (max-width: 580px) {
  .gtitle {
    font-size:13px !important;
  }
}
@media screen and (max-width: 450px) {
  .gtitle {
    font-size:10px !important;
    transform: translateX(-45px) !important;
  }
}
</style>