<template>
  <v-flex>
    <div class="title">Target Attainment</div>
    <v-container>
    <v-layout
    >
    <v-row>
     <template v-for="field in fields">
         <v-col 
          :key="field"
          cols="12"
          sm="12" 
          md="12" 
          lg="6"
          xl="6"
        >
        <component
          :is="field.type"
          :key="field.key"
          :graphTitle="field.label"
          :suggested-metric="suggestedMetric(field.key)"
          :success-metric="successMetric(field.key)"
        >
        </component>
        </v-col>
      </template>
    
    </v-row>
    </v-layout>
    </v-container>
  </v-flex>
</template>
<script>
import Attainment from "@/new-components/patient/Attainment";
import { get } from "lodash";
// import schema from "../../assets/event";
export default {
  name: "TargetAttainment",
  data() {
    return {
      fields: []
    };
  },
  components: { Attainment },
  props: {
    schema: { type: Array, required: true },
    suggestedMetrics: { type: Object, required: true },
    successMetrics: { type: Object, required: true },
  },
  computed: {},
  mounted() {
    console.log(this.fields);
    this.fields = this.schema;
  },
  methods: {
    suggestedMetric(key) {
      return get(this.suggestedMetrics, key, 0);
    },
    successMetric(key) {
      return get(this.successMetrics, key, 0);
    }
  }
};
</script>
