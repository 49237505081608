<template>
  <v-select
    :id="id"
    placeholder="Select"
    :value="value"
    :items="items"
    :rules="applyRules"
    :error="isNotValid"
    :disabled="allowUserToEditOrNot"
    @input="onChange($event)"
    @change="onChange($event)"
  >
    <span slot="label" v-html="label"></span>
    <span>{{ appendText }}</span>
  </v-select>
</template>

<script>
import validateInputMixin from "@/mixins/validateInputMixin";

export default {
  name: "Dropdown",

  mixins: [validateInputMixin],

  props: {
    allowUserToEditOrNot: { type: Boolean, required: false },
    items: { type: Array, required: true },
    fieldKey: { type: String },
    appendText: String,
  },
  mounted() {},
  methods: {
    onChange(value) {
      console.log(value);
      // if (this.min > value) {
      //   value = this.min;
      // }
      // if (this.max < value) {
      //   value = this.max;
      // }
      // this.inputValue = value;
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
</script>

<style>
.v-list-item__content,
.v-select__selection {
  text-transform: capitalize !important;
}
</style>
